<template>
    <van-tabbar v-model="active">
        <van-tabbar-item to="/home" >
            <span>主頁</span>
            <img slot="icon"   :src="this.active == 0 ? icon.active : icon.inactive" >
        </van-tabbar-item>
        <van-tabbar-item   to="/playMethod">
            <span>玩法介紹</span>
            <img slot="icon" :src="this.active == 1 ? icon.active1 : icon.inactive1">
        </van-tabbar-item>
    
        <van-tabbar-item to="updateLogs">
            <span>更新日誌</span>
            <img slot="icon"  :src="this.active == 2 ? icon.active2 : icon.inactive2"/>
        </van-tabbar-item>
        <van-tabbar-item to="CommonProblem">
            <span>常見問題</span>
            <img slot="icon" :src="this.active == 3 ? icon.active3 : icon.inactive3"/>
        </van-tabbar-item>
        <van-tabbar-item href="http://www.baidu.com"  v-show="false" @click="jump">
            <span>特惠儲值</span>
            <img slot="icon" :src="this.active == 4 ? icon.active4 : icon.inactive4">
        </van-tabbar-item>
        
    </van-tabbar>
</template>

<script>

export default {
  components: {

  },
  data() {
        return {
            isIos:false,
            active:this.activeIndex,
            icon: {
              active: require('@/assets/image/home_hover.png'),
              inactive: require('@/assets/image/home.png'),
              active1: require('@/assets/image/play_hover.png'),
              inactive1: require('@/assets/image/play.png'),
              active2: require('@/assets/image/log_hover.png'),
              inactive2: require('@/assets/image/log.png'),
              active3: require('@/assets/image/problem_hover.png'),
              inactive3: require('@/assets/image/problem.png'),
              active4: require('@/assets/image/pay_hover.png'),
              inactive4: require('@/assets/image/pay_hover.png'),
            },
        }
    },
    
    computed: {
        
    },
    created(){
        this.getDeviceModel()
    },
    mounted:{
        
    },
    methods: {
        jump(){
            if(this.env == 'test'){
            this.jumpUrl = "http://192.168.1.97:88"
            }
            if(this.env == 'pro'){
                this.jumpUrl = "https://landx.boloplay.com/#/pay"
            }
            if(this.env == 'local'){
                this.jumpUrl = "https://landx.boloplay.com/#/pay"
            }
            window.location.href = this.jumpUrl
        },
        getDeviceModel(){
            var u = navigator.userAgent;
            this.isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        },
        palyMethod(){
            this.$router.push({
                name:'playMethod',
                query:{
                    

                },
                active:this.active
            })
        },

        UpdateLogs(){
            this.$router.push({
                name:'updateLogs',
                query:{

                },
                active:this.active
            })
        },
        problem(){
            this.$router.push({
                name:'problem',
                query:{

                }
            })
        },
        pay(){
            this.$router.push({
                name:'pay',
                query:{

                }
            })
        },
        // getage(){
            
        // }

    },
    props:['activeIndex'],
};




</script>

<style>


body{
    background-image: url('../assets/image/bg.png');
}
.head_logo{
    width: 65px;
    height :45px;
    margin: 0px auto;
    margin-top: 5px;
}
.head_logo img{
    width: 65px;
    height :45px;
}
/*
.van-tabbar{
    z-index: 3;
    background:linear-gradient(to bottom,#FFBB38,#FA793A);
}
.van-tabbar-item{
    color: #ffffff;
}
.van-tabbar-item--active{
    color: #FFEE78;
    background:linear-gradient(to bottom,#FFEE78,#FA793A);
}
.van-tabbar-item--inactive{
    color: #ffffff;
}
.icon .active{
    color: #FFEE78;
}
.icon .inactive{
    color: #ffffff;
}
.side_edge{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    z-index: 2;
    display: none;
}
.side_edgen_content{
    height: 100%;
    width: 50%;
    background-color: #FFB049;
    z-index: 3;
}
.side_edge_item{
    height: 50px;
    width: 100%;
    border-bottom: 2px solid #FFEE78;
    background:linear-gradient(to bottom,#FFEE78,#FA793A);
}
.side_edge_item img{
    float: left;
    height: 20px;
    margin: 15px 5px 5px 10px;
}
.side_edge_item div{
    float: left;
    font-size: 18px;
    line-height: 50px;
    color: #FFF88F;
}
*/
</style>

