<template>
    <div>
        <div class="head">
            <div class="head_left" @click="getNavList">
                <div class="item_daohang"><img src="../assets/image/daohang.png" alt=""></div>
            </div>
            <div class="head_right">
                <div class="head_logo">
                    <img src="../assets/image/logo.png" alt="">
                </div>
            </div>
        </div>
        <div class="side_edge" @click="quitList" v-show="acitve">
            <div class="side_edgen_content">

                    <div class="side_edge_item" @click="JumpHome">
                        <img src="../assets/image/home_hover.png" alt="">
                        <div class="side_edge_title">主頁</div>
                    </div>
        
       
                    <div class="side_edge_item" @click="JumpPlayMethod">
                        <img src="../assets/image/play_hover.png" alt="">
                        <div class="side_edge_title">玩法介紹</div>
                    </div>
                    <div class="side_edge_item" @click="JumpUpdateLogs">
                        <img src="../assets/image/log_hover.png" alt="">
                        <div class="side_edge_title">更新日誌</div>
                    </div>
                    <div class="side_edge_item" @click="JumpCommonProblem">
                        <img src="../assets/image/problem_hover.png" alt=""> 
                        <div class="side_edge_title">常見問題</div>
                    </div>
                    <div class="side_edge_item" @click="JumpPay">
                        <img src="../assets/image/pay_hover.png" alt="">
                        <div class="side_edge_title">特惠儲值</div>
                    </div>
                
            </div>
        </div>
    </div>
    

</template>

<script>
 
export default {
    components: {

    },
    data() {
        return {
            acitve:false
        };
    },
    computed: {
            
    },
    methods: {
        getNavList(){
            this.acitve = this.acitve ? false :true
            console.log(this.acitve)
            console.log(1111)
        },
        JumpHome(){
            this.$router.push('/home')
        },
        JumpPlayMethod(){
            this.$router.push('/playMethod')
        },
        JumpUpdateLogs(){
            this.$router.push('/updateLogs')
        },
        JumpCommonProblem(){
            this.$router.push('/commonProblem')
        },
        JumpPay(){
            this.$router.push('/pay')
        },
        quitList(){
            this.acitve = false
            console.log(this.acitve)
            console.log(1111)
        },
    }
};



</script>

<style>

.head{
    width: 100%;
    height: 56px;
    position: relative;
    background:linear-gradient(to bottom,#FF923D,#FF743D);
    border-bottom:2px solid #FFEE78;
    box-shadow: 2px 0px 10px 0px #ba5701; 
}
.head_left{
    width:56px;
    height: 56px;
    position: absolute;
    left: 0;
    z-index: 2;
}

.item_daohang img{
    width: 30px;
    height: 30px;
    margin-top: 13px;
    margin-left: 10px;
}
.head_right{
    width: 100%;
    float: right;
    left: 0;
    position: absolute;
    border-bottom: 2px;
}
body{
    background-image: url('../assets/image/bg.png');
}
.head_logo{
    width: 65px;
    height :45px;
    margin: 0px auto;
    margin-top: 5px;
}
.head_logo img{
    width: 65px;
    height :45px;
}

.van-tabbar{
    z-index: 3;
    background:linear-gradient(to bottom,#FFBB38,#FA793A);
}
.van-tabbar-item{
    color: #ffffff;
}
.van-tabbar-item--active{
    color: #FFEE78;
    background:linear-gradient(to bottom,#FFEE78,#FA793A);
}
.van-tabbar-item--inactive{
    color: #ffffff;
}
.icon .active{
    color: #FFEE78;
}
.icon .inactive{
    color: #ffffff;
}

.side_edge{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background-color: rgba(0,0,0,0.6);
    z-index: 2;
}

.side_edgen_content{
    height: 100%;
    width: 50%;
    background-color: #FFB049;
    z-index: 3;
}
.side_edge_item{
    height: 50px;
    width: 100%;
    border-bottom: 2px solid #FFEE78;
    background:linear-gradient(to bottom,#ffc44f,#FA793A);
}
.side_edge_item img{
    float: left;
    height: 20px;
    margin: 15px 5px 5px 10px;
}
.side_edge_item div{
    float: left;
    font-size: 18px;
    line-height: 50px;
    color: #FFF88F;
}

</style>

